<template>
  <div>
    <v-sheet height="40" color="#fff" class="d-flex pt-0">
      <div
        :class="{
          'mx-1': $vuetify.breakpoint.smAndDown
        }"
        class="pb-1 expande-horizontal centraliza"
      >
        <v-avatar
          style="cursor: pointer;"
          size="32"
          color="#444"
          rounded
          @click="$refs.calendar.prev()"
        >
          <v-icon :color="$theme.primary">mdi-chevron-left</v-icon>
        </v-avatar>
        <v-chip
          outlined
          v-if="$refs.calendar"
          :color="$theme.primary"
          dark
          class="mx-1 black--text text-uppercase px-3 fonte"
          :style="`min-width: 200px; border-radius: 3px;`"
          >{{ $refs.calendar.title }}</v-chip
        >
        <v-avatar
          style="cursor: pointer;"
          size="32"
          color="#444"
          rounded
          @click="$refs.calendar.next()"
        >
          <v-icon :color="$theme.primary">mdi-chevron-right</v-icon>
        </v-avatar>
      </div>
      <v-select
        v-model="type"
        :items="types"
        dense
        solo
        background-color="#f2f2f2"
        flat
        class="mt-0 pb-1"
        hide-details
        :color="$theme.primary"
        item-text="name"
        item-value="value"
        label="Visualização"
      ></v-select>
      <v-select
        v-model="weekday"
        :items="weekdays"
        dense
        v-if="$vuetify.breakpoint.smAndUp"
        solo
        background-color="#f2f2f2"
        flat
        class="mt-0 pb-1 ml-1"
        :color="$theme.primary"
        hide-details
        label="Dias da semana"
      ></v-select>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="getEvents"
        event-overlap-mode="stack"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="$emit('open-event', $event.event)"
        @click:more="openDaySelected($event)"
        @click:date="$emit('create-event', $event)"
        @change="$forceUpdate()"
      ></v-calendar>
    </v-sheet>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    type: "day",
    types: [
      {
        name: "Mês todo",
        value: "month"
      },
      {
        name: "Semana",
        value: "week"
      },
      {
        name: "Dia",
        value: "day"
      }
    ],
    weekday: [1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Segunda até Sábado", value: [1, 2, 3, 4, 5, 6] },
      { text: "Segunda até Domingo", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Segunda até Sexta", value: [1, 2, 3, 4, 5] }
    ],
    value: ""
  }),
  computed: {
    getEvents() {
      return this.events.map(event => {
        return {
          ...event,
          start: this.$moment(
            event.visit_date + event.visit_hour || "00:00",
            "DD/MM/YYYYHH:mm"
          ).format("YYYY-MM-DD")
        };
      });
    }
  },
  methods: {
    openDaySelected(date) {
      this.type = "day";
      this.value = date.date;
    },
    getEventColor(event) {
      let status_type = {
        waiting: "orange",
        on_the_way: "blue",
        done: "green",
        canceled: "red"
      };
      return status_type[event.status] || "grey darken-3";
    }
  }
};
</script>
